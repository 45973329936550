import { createAsyncThunk } from "@reduxjs/toolkit";
import { createDiscussionThreadNote, getDiscussionThreadNotes } from "services/comments";
import { DiscussionThreadNoteType } from "./discussionThreadSlice";
import { AppState } from "store/appReducer";

export const fetchDiscussionThreadNotes = createAsyncThunk<DiscussionThreadNoteType[], number>(
  "discussionThread/fetchDiscussionThreadNotes",
  async (commentId): Promise<DiscussionThreadNoteType[]> => {
    const { data: discussionThreadNotes } = await getDiscussionThreadNotes(commentId);
    if (!discussionThreadNotes) {
      throw new Error("Failed to fetch notes.");
    }
    return discussionThreadNotes.notes;
  }
);

export const submitDiscussionThreadNote = createAsyncThunk<
  DiscussionThreadNoteType,
  void,
  { state: AppState }
>(
  "discussionThread/submitDiscussionThreadNote",
  async (_, { getState }): Promise<DiscussionThreadNoteType> => {
    const state = getState();
    const { data } = await createDiscussionThreadNote(
      state.discussionThread.comment.id,
      state.discussionThread.userInput
    );

    if (!data) {
      throw new Error("Failed to submit note.");
    }

    const submittedNote = {
      id: data.noteId,
      note: state.discussionThread.userInput,
      createdBy: state.auth.currentUser.id.toString(),
      createdDate: data.timestamp,
      firstname: state.auth.currentUser.firstname,
      lastname: state.auth.currentUser.lastname,
      isEdited: false,
    };

    return submittedNote;
  }
);
