import React, { useState } from "react";

import { setComment } from "store/notes/discussionThreadSlice";
import { useAppDispatch } from "store";
import { useResource } from "hooks/useResource";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { Tooltip } from "components/Tooltip";
import { SideDrawer } from "components/SideDrawer";
import { DiscussionThreadDrawer } from "./DiscussionThreadDrawer";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { Comment } from "ts/comments";

type Props = {
  comment: Comment;
};

export const DiscussionActionButton = ({ comment }: Props) => {
  const dispatch = useAppDispatch();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { getResource } = useResource();

  const handleClick = () => {
    setIsOpen(true);
    dispatch(setComment(comment));
    if (showTooltip) setShowTooltip(false);
  };

  return (
    <div
      onMouseEnter={() => !isOpen && setShowTooltip(true)}
      onMouseLeave={() => !isOpen && setShowTooltip(false)}
      data-tooltip-id={"discussion-thread-icon"}
    >
      <Button
        variant={ButtonVariant.light}
        square
        size={ButtonSize.sm}
        onClick={handleClick}
        style={{ color: Color.gray20 }}
      >
        <Icon type={IconType.commentBubble} color={Color.gray20} size={12} />
      </Button>
      {showTooltip && (
        <Tooltip
          tooltipId={"discussion-thread-icon"}
          content={getResource("comments.discussionThread.tooltip")}
          isOpen
        />
      )}

      <SideDrawer
        render={DiscussionThreadDrawer}
        isOpen={isOpen}
        closeHandler={() => setIsOpen(false)}
        renderComponentProps={{
          closeHandler: () => setIsOpen(false),
        }}
        width="462px"
      />
    </div>
  );
};
