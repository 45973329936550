import React, { useState } from "react";
import styled from "styled-components";

import { DiscussionThreadNoteType } from "store/notes/discussionThreadSlice";
import { getLocalTime } from "utils/getLocalTime";
import { useTimeStamp } from "hooks/useTimeStamp";

import { UserAvatar } from "components/UserAvatar.tsx";
import { DotsMenu } from "components/DotsMenu";
import { Text } from "components/Text";
import { TextTruncator } from "components/TextTruncator";

import { Color } from "ts/enums/color";
import { DropdownSize } from "ts/dropdown";
import { DotsButtonVariant } from "ts/enums/dotsButton";

type Props = {
  noteContent: DiscussionThreadNoteType;
};

export const DiscussionThreadNote = ({ noteContent }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const getTimeStamp = useTimeStamp();

  const menuContents = [
    { label: <Text resource="comments.discussionThread.sideDrawer.dotsDropdown.edit" /> },
    { label: <Text resource="comments.discussionThread.sideDrawer.dotsDropdown.delete" /> },
  ];

  const noteCreationDate = getLocalTime(new Date(noteContent.createdDate));

  return (
    <StyledNoteContainer>
      <StyledAvatarContainer>
        <UserAvatar
          firstName={noteContent.firstname}
          lastName={noteContent.lastname}
          userId={parseInt(noteContent.createdBy)}
        />
      </StyledAvatarContainer>
      <StyledNoteBubble>
        <StyledTopRow>
          <StyledNameDate>
            <TextTruncator
              value={noteContent.firstname + " " + noteContent.lastname}
              customWidth="150px"
            />
            <StyledDot>•</StyledDot>
            <div>{getTimeStamp(noteCreationDate)}</div>
          </StyledNameDate>
          <DotsMenu
            menuContents={menuContents}
            showDotsButton
            shouldCloseMenuOnClick
            size={DropdownSize.md}
            dotsButtonVariant={DotsButtonVariant.Transparent}
            onDotsMenuClick={() => setIsDropdownOpen((prev) => !prev)}
            dotsMenuOpen={isDropdownOpen}
            setDotsMenuOpen={setIsDropdownOpen}
            menuWidth={130}
          />
        </StyledTopRow>
        <StyledComment>{noteContent.note}</StyledComment>
      </StyledNoteBubble>
    </StyledNoteContainer>
  );
};

const StyledNoteContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
`;

const StyledTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: ${Color.gray30};
`;

const StyledNoteBubble = styled.div`
  width: 378px;
  padding: 12px;
  background-color: ${Color.sky15};
  border-radius: 12px;
  overflow-wrap: break-word;
  white-space: normal;
`;

const StyledNameDate = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledComment = styled.div`
  white-space: pre-line;
  color: ${Color.gray50};
  font-size: 15px;
`;

const StyledAvatarContainer = styled.div`
  margin-top: 12px;
`;

const StyledDot = styled.div`
  font-weight: normal;
`;
